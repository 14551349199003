.card-preloader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
}

.card.no-shadow {
  box-shadow: none;
}

.card {
  .instruction-description {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $gray-600;
  }
}

.card-total-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 4px;
  font-family: Poppins;
  margin: 10px 0;
  border-bottom: 1px solid $table-border-color;
  background: rgba(248, 248, 251, 1);

  > label {
    margin: 0;
  }

  .total-cost-label {
    font-weight: normal;
  }

  .total-cost-value {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }
}

$free-trial-bar-height: 23px;

.is-limited-time-plan {

  #page-topbar,
  .vertical-menu,
  .vertical-menu .navbar-brand-box {
    top: $free-trial-bar-height;
  }

  .page-content {
    padding-top: calc(#{$header-height} + #{$grid-gutter-width} + #{$free-trial-bar-height});
  }
}

.free-trial-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $success;
  text-align: center;
  padding: 3px 0;
  font-size: 11px;
  color: $white;
  border-bottom: 1px solid #27926b;
  z-index: 1003;

  &.is-plan-expired {
    background-color: $danger;
    border-color: #b75050;
  }
}
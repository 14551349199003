/*********** MY PROFILE ***********/

.my-profile {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  position: relative;
  height: 100%;
}

.my-profile-header {
  height: 272px;
  background: #D4DAF9;
  background-image: url(../../images/profile-img.png);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right top;
  text-align: left;
  padding: 20px;
  border-radius: 4px;
}

.my-profile-header>p,
.my-profile-header>h5 {
  color: $primary;
}

.my-profile-avatar {
  border: 10px solid white;
  position: absolute;
  top: 174px;
  left: 20px;

  i {
    font-size: 52px;
  }
}

.my-profile-content {
  padding: 74px 20px 20px 20px;
  border-radius: 4px;
}

/*********** MESSAGES ***********/

.messages-card {
  height: 440px;
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 0;

  .message-content {
    width: 35%;
    max-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .horizontal-scroll-table {
    max-height: 350px;
    overflow: auto;
  }

  thead {
    background-color: rgba(248, 249, 250, 1);
  }
}

.table-badge {
  border-radius: 30px;
  font-size: 11px;
  font-weight: 400;
  padding: 6px 16px;
}

/*********** WIP ORDERS ***********/

.wip-orders-card {
  height: 100%;
  border-radius: 4px;
}

/*********** MESSAGES COMING SOON ***********/

.bullets-list {
  padding-left: 0;
  list-style-position: inside;

  li {
    color: $gray-700;
    font-size: 13px;
  }

  li::marker {
    color: $gray-700;
    content: "• ";
  }
}

.light-purple-bg {
  background-color: #D4DAF9;
}

.email-address {
  font-weight: $font-weight-bold;
  color: $link-color;
}

/*********** STATS AND ACTIONS ***********/

.action-card {
  height: 130px;
  cursor: pointer;
}

.stats-icon {
  align-items: center;
  display: flex;
  font-weight: $fw-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.primary-icon-color {
  background-color: rgba($primary, 0.4);
  color: $primary;
}

.primary-icon-color-light {
  background-color: rgba($primary, 0.15);
  color: $primary;
}

.purple-icon-color {
  background-color: rgba($purple, 0.15);
  color: $purple;
}

.green-icon-color {
  background-color: rgba($green, 0.15);
  color: $green;
}

.icon-circle {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  min-width: 48px;
}

.action-link,
.action-link:hover {
  color: $gray-700;
}

.footer-link {
  position: absolute;
  right: 20px;
  bottom: 10px
}

/*********** CAROUSEL ***********/

.carousel-mav {
  height: 440px;

  .carousel-status,
  .control-next,
  .control-prev {
    display: none;
  }

  .slide {
    background: white;
    padding-bottom: 40px;
  }

  .slide-title {
    font-size: 38px;
    font-weight: 700;
    color: white;
    text-align: left;
    font-family: 'Lato', sans-serif;
    margin-bottom: 20px;

    @media screen and (max-width: 1037px) {
      font-size: 24px;
    }
  }

  .slide-title-esign {
    max-width: 350px;
  }

  .slide-title-ink {
    max-width: 400px;
  }

  .slide-content {
    font-size: 22px;
    color: white;
    text-align: left;
    font-family: 'Lato', sans-serif;

    @media screen and (max-width: 1037px) and (min-width: 768px) {
      font-size: 16px;
    }
  }

  .long-text-resize {
    @media screen and (max-width: 1600px) and (min-width: 768px) {
      .slide-title {
        font-size: 30px;
      }

      .slide-content {
        font-size: 18px;
      }
    }
  }

  .position-slide-text {
    @media screen and (min-width: 768px) {
      position: absolute;
      bottom: 30px;
      left: 60px;
    }
  }

  .slide-responsive {
    height: 100%;
    padding: 0;

    @media screen and (max-width: 768px) {
      display: flex;
      align-items: center;
      padding: 20px;
    }
  }

  .slide-background {
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px) {
      background-position: top center;
    }
  }

  #slide-1 {
    background-image: url(../../images/carousel/vid.png);
  }

  #slide-2 {
    background-image: url(../../images/carousel/eSign.png);
    background-position: top 0px left -60px;
  }

  #slide-3 {
    background-image: url(../../images/carousel/inkSign.png);
    margin: -1px; // fix glitch
    background-position: top 0px left -30px;
  }

  #slide-4 {
    background-image: url(../../images/carousel/seal.png);

    @media screen and (min-width: 768px) {
      background-position: top right;
    }
  }
}

.carousel {
  height: 100%;

  .control-dots {
    margin: 10px 0px;
  }

  .dot {
    background: white !important;
  }

  .slider-wrapper,
  .slider,
  .slide,
  .item {
    height: 100%;
  }

  .slide {
    padding: 0;
  }
}

.carousel-root {
  height: 100%;
}

/*********** DOUGHNUT CHART ***********/

.btn-active {
  color: black !important;
  border: none;
}

.btn-active:focus {
  color: #ffff !important;
}

.img-store {
  width: 6em !important;
  height: auto;
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.chart-placeholder {
  text-align: center;

  .placeholder-description {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    color: $gray-650;
  }
}

/*********** TOP ***********/

.top-card {
  min-height: 440px;
}

.top-store-name {
  color: $gray-700;
  font-size: 13px;
}

.progress-col {
  width: 45%;
}

.badge-placeholder {
  width: 32px;
  height: 32.24px;
}

/*********** TIME TO COMPLETION ***********/

.time-to-completion-card {
  height: 438px;
}

/*********** CALL A CUSTOMER ***********/

.call-a-customer-card {
  height: 440px;
}

/***********  CUSTOMER PERFORMANCE ***********/
.remote-customer-perf-card {

  .orders-status-slider {

    .carousel {

      .carousel-controls {

        .carousel-control-prev,
        .carousel-control-next {
          position: static;
          width: 30px;
          height: 30px;
          display: block;
        }

        .carousel-control-prev-icon {
          background-image: url('../../images/carousel-left-arrow.svg');
          background-size: auto;
        }

        .carousel-control-next-icon {
          background-image: url('../../images/carousel-right-arrow.svg');
          background-size: auto;
        }
      }

      .carousel-indicators {
        position: static;
        text-align: center;
        margin: 0;

        button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #74788D40;
          opacity: 1;
          border: 0;
          margin: 0 10px;

          &.active {
            background: #74788D;
          }
        }
      }

    }
  }

  .overlap-chart {
    z-index: 999;
  }

  .card-header {
    justify-content: flex-end;
  }
}

/*********** COMMON ***********/

// used to add an horizontal scrollbar when content is too wide
.horizontal-scroll-table {
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
}

.horizontal-scroll-table>table {
  @media screen and (max-width: 768px) {
    width: 200%;
  }
}

/*********** TOP ***********/

.td-name {
  width: 45%;
}

/*********** CHECK INFO ALERT *********/

.info-alert-link {
  font-weight: 600;

  &:hover {
    color: #924040;
  }
}

/*********** Chat Screen *********/

.sender-message-name {
  color: #556ee6;
}

.message-parent {
  white-space: nowrap;
  display: flex;
}


.message-content-mobile {
  max-width: 50px;
  display: inline-block
}

.message-card-mobile {
  max-width: 100px;
  display: inline-block
}
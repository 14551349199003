$close-btn-color: #323232;

.tracking-modal {
  .modal-content {
    padding: 35px 20px;

    .tracking-modal-header {
      .btn-close {
        position: absolute;
        right: 10px;
        top: 28px;
        color: $close-btn-color;
      }

      .tracking-modal-title {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 500;
        line-height: 29px;
      }
    }

    .modal-body {
      padding: 0;

      .table {
        thead {
          background: $gray-100;
        }
      }

      .package-number {
        font-size: 13px;
        line-height: 20px;
      }

      .btn {
        min-width: 140px;
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 60%;
  }
}

.button-alert {
  width: 40%;
}

.special-instruction {
  h2 {
    color: $gray-700 !important;
  }
}
.modal-alert-container {
  border: 1px solid $white-smoke;
  padding: 16px;
  text-align: left;
}
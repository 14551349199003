body[data-sidebar="dark"] .navbar-brand-box,
.full-width-layout .navbar-brand-box {
  background-color: #1D71B8;
  height: 70px;
}

.full-width-layout {
  background-color: $body-bg;

  .logo-light {
    display: block;
  }

  #vertical-menu-btn {
    display: none;
  }

  .vertical-menu {
    bottom: auto;
    padding-bottom: 0;
  }

  .main-content {
    margin-left: 0;
  }
}

.logo-size-33 {
  width: 33px;
  height: 33px;
}

.main-content {
  // fill the viewport vertically
  min-height: 100vh;
}

.footer {
  // expand all the way to the left
  left: 0;
  // display over the vertical menu
  z-index: 1001;
}

.vertical-menu {
  // footer height + some margin
  padding-bottom: 70px;
}

.spinner-chase.sm {
  width: 32px;
  height: 32px;
}

.spinner-chase.dtable {
  // give some bottom spacing to the spinner
  // datatable row height - spinner height
  margin-bottom: calc(44.5px - 32px);
}

.is-invalid .select2-selection__control {
  // show a red border around an invalid select2 control
  // to match the error styling of the other controls
  border-color: #f34943 !important;
}

.select2-selection__menu {
  // display over the footer
  z-index: 1002 !important;
}

.card-header:first-child {
  // add rounded borders to the card header
  border-radius: 0.25rem 0.25rem 0 0;
}

#preloader.inner {
  background-color: transparent;
  // account for the vertical-menu
  // so the preloader appears centered in the right-side frame
  left: 250px;
}

.card.expand-v {
  // expand card vertically as much as possible
  // so adjacent cards will be equal in height
  height: calc(100% - 24px);
}

.badge-lg {
  padding: 8px;
  font-size: inherit;
}

.badge-md {
  padding: 4px 8px;
  font-size: inherit;
}

.me-45 {
  // intermediate value between .me-4 and .me-5
  margin-right: 2rem !important;
}

.card-menu-btn {
  font-size: 20px;
  line-height: 1;
  color: $secondary;
}

.section-rows {
  margin-bottom: 0;

  td {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }
}

.section-row-label {
  width: 30%;
  padding-right: 10px !important;
}

.section-row-value {
  font-size: 16px;
  font-weight: 500;
}

.card-section {
  padding: 0 $card-spacer-x;
}

.card,
.card-section {

  &.blue {
    background-color: #EEF4FD;
  }

  // Supporting docs label
  .supporting-docs-label {
    background: #EEF0FD;
    padding: 8px 16px;
    border-radius: 8px;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: $blue;
  }
}

.btn-faded {
  opacity: 0.65;
}

.table {

  &.bt-1 {
    border-top-width: 1px;
    border-top-style: solid;
  }

  &.bb-0 tr:last-child td {
    border-bottom-width: 0;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.text-right {
  text-align: right;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.form-check-input:disabled+.form-check-label {
  // disabled checkboxes/switches should appear as not clickable
  cursor: default;
}

.progress-button {
  position: relative;

  &.disabled {
    // do not fade using opacity because that affects the entire button
    opacity: 1;

    &.btn-primary {
      // set a faded shade of color only for background and border
      background-color: #958fee;
      border-color: #958fee;
    }
  }

  .btn-progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: $primary;
  }

  .btn-dark:hover {
    color: #fff;
    background-color: #23272B;
    border-color: #1D2124;
  }

  .btn-dark:focus,
  .btn-dark.focus {
    color: #fff;
    background-color: #23272B;
    border-color: #1D2124;
    -webkit-box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5);
    box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5);
  }

  span {
    position: relative;
  }
}

.page-title-box h4 {
  // since we are combining page title with breadcrumbs
  // do not make title upercase by default
  // we want to have the flexibility to choose on a case by case basis
  text-transform: none;
}

.editable-content-text {
  cursor: pointer;
  color: $primary;
}

.top-heading {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
  font-size: 16px;
}

.auth-user-name {
  vertical-align: middle;
  line-height: 1.2;
}

.auth-user-role {
  display: block;
  font-size: 70%;
}

.square-icon-btn {
  width: 36px;
  height: 36px;
  padding: 0;

  i {
    font-size: 20px;
  }
}

@media(min-width: 1200px) {
  .hidden-desktop {
    display: none;
  }
}

@media(max-width: 1199px) {
  .hidden-tablet {
    display: none;
  }
}
.datepicker-input-group {

  .input {
    margin: 0;
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
    background-color: transparent;
  }

  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: $secondary !important;
    border-color: $input-border-color;
    opacity: 1;
  }

  &.is-invalid {

    .input {
      border-color: $danger;
    }

    .btn {
      border-color: $danger;
    }
  }
}
/********** DOC VIEWER **********/

.doc-viewer-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1010;
}

.doc-viewer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.doc-viewer-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  // 20 = half the size of the loader
  margin: -20px 0 0 -20px;
}

.doc-viewer-fs-wrapper {
  height: 100%;
}

.doc-viewer-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #B8BFC4;
  padding: 24px;
}

// Doc Viewer Header

.doc-viewer-header {
  flex: 0 0 auto;
  padding: 15px 11px;
  background-color: #333639;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.24);
  display: flex;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;

  .btn {
    font-size: 24px;
    color: inherit;
    line-height: 1;
    padding-left: 6px;
    padding-right: 6px;

    &:focus {
      box-shadow: none;
    }

    &.disabled,
    &:disabled {
      opacity: 0.4;
    }
  }
}

.doc-viewer-header-col {
  flex: 1 1 auto;
}

.doc-viewer-title {
  margin: 0;
  font-size: inherit;
  display: inline-block;
  vertical-align: middle;
  color: inherit;
}

.doc-viewer-page-nav,
.doc-viewer-zoom {
  display: inline-block;
  vertical-align: middle;
}

.doc-viewer-header-input {
  display: inline-block;
  vertical-align: middle;
  padding: 3px 7px 0;
  height: 20px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1px;
  font-size: inherit;
  font-weight: inherit;
  border: none;
  color: inherit;
  line-height: 19px;
  margin-top: -1px;

  &:focus,
  &[readonly] {
    background: rgba(0, 0, 0, 0.8);
    color: inherit;
  }
}

.doc-viewer-page-input {
  width: 35px;
  margin-right: 4px;
  text-align: right;
}

.doc-viewer-zoom-input {
  width: 52px;
  text-align: right;
  padding-right: 20px;
  margin-left: 6px;
}

.doc-viewer-zoom-percent {
  display: inline-block;
  margin: 1px 6px 0 -18px;
  font-size: 15px;
  vertical-align: middle;
  padding-right: 6px;
}

.doc-viewer-header-sep {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  border-right: 1px solid #717375;
  margin: 0 6px;
}

// Doc Viewer Body

.doc-viewer-body {
  flex: 1 1 100%;
  height: 100%;
  display: flex;
  margin-top: 12px;
  // important so child overflow works
  overflow: auto;
}

// Doc Viewer Content

.doc-viewer-content {
  flex: 1 1 100%;
  overflow-y: auto;
}

.doc-viewer-page-list-wrapper {
  // so it centers when zoom < 100
  margin: 0 auto;
  // hide scrollbars
  overflow: hidden;
}

.doc-viewer-page-list {
  transform-origin: left top;
}

.doc-viewer-page {
  text-align: center;

  &:not(:last-child) {
    padding-bottom: 24px;
  }
}

.doc-viewer-page-content {
  // also important for calculating child offsetTop
  position: relative;

  &.loaded {
    background-color: white;
  }

  .spinner-chase {
    margin: 20px auto;
  }
}

.doc-viewer-page-image {
  width: 100%;
  height: auto;
}

.doc-viewer-drop-target {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  // fields dropped partially outside must not generate scroll
  overflow: hidden;

  &.clickable {
    cursor: crosshair;

    .doc-viewer-field {
      // should not drop on field
      cursor: default;
    }
  }
}

.doc-viewer-drag-layer {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

// Doc Viewer LeftBar

.doc-viewer-left-bar {
  flex: 0 0 280px;
  height: 100%;
  overflow-y: auto;
  background-color: #2C2F33;
  box-shadow: inset -1px 2px 8px rgba(0, 0, 0, 0.16);
  display: none;
  margin-right: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;

  &.open {
    display: block;
  }
}

.doc-viewer-page-thumb {
  margin: 40px 0;
  text-align: center;

  &.loaded {

    .doc-viewer-page-image {
      background-color: white;
      max-width: 70%;
      // parent bg color
      border: 6px solid #2C2F33;
      cursor: pointer;
    }
  }

  &.active {

    .doc-viewer-page-image {
      border-color: #93B3F2;
    }
  }
}

.doc-viewer-page-num {
  margin-top: 16px;
}

// Doc Viewer RightBar

.doc-viewer-right-bar {
  flex: 0 0 auto;
  height: 100%;
  overflow-y: auto;
  margin-left: 12px;
  min-width: 150px;
}

.doc-viewer-signers-menu {
  padding: 0 12px;
  background-color: #ffffff;
  border-radius: 16px;

  &:not(:first-child) {
    margin-top: 12px;
  }

  .accordion-item {
    padding: 12px 0;
    border-color: #D4DAF9;

    button {
      background-color: #66D2B9;
    }
  }

  .accordion-item.signer-type-1.signer-position-1 button {
    background-color: #AAB6F2;
  }

  .accordion-item.signer-type-1.signer-position-2 button {
    background-color: #50A5F1;
  }

  .accordion-header {
    white-space: nowrap;
  }

  .accordion-button {
    border-radius: 33px !important;
    font-size: 8px;
    font-weight: 700;
    line-height: 12px;
    color: #000000;
    padding: 6px 4px;

    span {
      flex: 1 1 100%;
      text-align: center;
    }

    &::after {
      flex: 0 0 auto;
      font-family: boxicons !important;
      content: "\eafd";
      width: 12px;
      height: 12px;
      line-height: 12px;
      text-align: center;
      background-color: #ffffff;
      background-image: none;
      color: $gray-700;
      border-radius: 50%;
    }

    &:not(.collapsed) {
      box-shadow: none;
    }

    &:not(.collapsed)::after {
      transform: none;
      content: "\eace";
    }
  }

  .accordion-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

// Doc Viewer Field

.doc-viewer-field {
  background-color: rgba(0, 183, 140, 0.6);
  border-radius: 32px;
  color: #000000;

  &.signer-type-1.signer-position-1 {
    background-color: rgba(130, 149, 249, 0.6);
  }

  &.signer-type-1.signer-position-2 {
    background-color: rgba(0, 130, 246, 0.6);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.doc-viewer-field-available {
  margin-top: 8px;
  height: 24px;

  &.is-dragged {
    opacity: 0.5;
  }

  &.selected {
    background-color: rgba(48, 162, 135, 1);
  }

  &.signer-type-1.signer-position-1.selected {
    background-color: rgba(127, 145, 235, 1);
  }

  &.signer-type-1.signer-position-2.selected {
    background-color: rgba(16, 115, 203, 1);
  }
}

.doc-viewer-field-added {

  &.is-dragged {
    // hide the actual field while dragging and only show the preview
    opacity: 0;
  }
}

.doc-viewer-field-dragged {
  // important so the field does not jump when dropped
  transform-origin: 0 0;
}

.doc-viewer-field-signed {
  border-radius: 0;
  background-color: transparent !important;
}

.doc-viewer-field-body {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 8px 8px 8px 16px;
  cursor: move;
}

.field-type-2 .doc-viewer-field-body {
  padding-top: 4px;
  padding-bottom: 4px;
}

.doc-viewer-field-available .doc-viewer-field-body {
  padding: 0;
  justify-content: center;
  cursor: pointer;
}

.doc-viewer-field-signed .doc-viewer-field-body {
  padding: 0;
}

.doc-viewer-field-signed .doc-viewer-field-body {
  cursor: default;
}

.doc-viewer-field-icon {
  flex: 0 0 32px;
  margin-right: 12px;
}

.field-type-2 .doc-viewer-field-icon {
  flex-basis: 24px;
}

.doc-viewer-field-available .doc-viewer-field-icon {
  flex-basis: 16px;
  margin-right: 6px;
}

.doc-viewer-field-content {
  flex: 1 1 100%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // important so child overflow works
  overflow: hidden;
}

.doc-viewer-field-available .doc-viewer-field-content {
  flex: 0 0 auto;
  align-self: auto;
}

.doc-viewer-field-signer-name {
  flex: 0 0 auto;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
}

.field-type-2 .doc-viewer-field-signer-name {
  font-size: 8px;
  line-height: 9px;
}

.doc-viewer-field-label {
  flex: 0 0 auto;
  // subtract the height of the signer name
  max-height: calc(100% - 18px);
  text-align: left;
  overflow: hidden;
  font-size: 24px;
  line-height: 36px;

  .editable-content-field {
    width: 100%;
    max-height: 100%;
    border-radius: 0;
    border: none;
    background-color: transparent;
    resize: none;
    padding: 0;
    display: block;
  }

  .editable-content-text {
    color: inherit;
  }
}

.field-type-2 .doc-viewer-field-label {
  max-height: calc(100% - 12px);
  font-size: 12px;
  line-height: 18px;
}

.doc-viewer-field-available .doc-viewer-field-label {
  font-size: 7px;
  line-height: 9px;
  max-height: none;
}

.doc-viewer-field-signed .doc-viewer-field-label {
  flex-direction: column;
}

.doc-viewer-field-menu {
  flex: 0 0 auto;
  margin-right: -8px;

  .dropdown-toggle {
    padding: 7px;
    border: none;
    font-size: 24px;
    line-height: 0.8;

    &:focus {
      box-shadow: none;
    }
  }

  .dropdown-menu {
    padding: 0;
    background-color: #222222;
    border-radius: 9px;
    margin-top: -3px;
    min-width: 0;

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: -12px;
      width: 30px;
      height: 33px;
      background-image: url('../../images/docviewer/triangle.svg');
      background-size: contain;
    }
  }

  .dropdown-item {
    padding: 0.6rem 14px;
    font-size: 16px;
    color: #ffffff;
    // to come on top of the triangle
    position: relative;

    &:hover {
      background-color: rgba(#ffffff, 0.1);
    }
  }
}

.doc-viewer-signature-element {
  flex: 1 1 100%;
  // important so child does not expand beyond parent
  overflow: hidden;
}

.doc-viewer-signature-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.doc-viewer-signature-metadata {
  flex: 0 0 auto;
  color: $primary;
  border-top: 1px solid $primary;
  margin-top: 5px;
  font-size: 9px;
}

.react-resizable-handle {
  width: 18px;
  height: 18px;
}

.field-type-2 .react-resizable-handle {
  padding-right: 1px;
  padding-bottom: 1px;
}

// Doc Viewer Footer

.doc-viewer-footer {
  flex: 0 0 auto;
  padding: 24px;
  margin: 12px -24px -24px;
  background-color: #ffffff;
  text-align: right;
}

.temp-grid {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  flex-flow: row wrap;
  display: none;

  div {
    flex: 1 1 50%;
    border: 1px solid #000;
  }
}
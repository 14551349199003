.subscription-wizard {

  .subscription-store-info-form {

    h3 {
      font-size: 15px;
      margin: 0 0 7px 0;
      font-weight: 600;
      line-height: $line-height-base;
    }
  }

  .section-rows {

    td:first-child {
      width: 210px;
    }
  }

  .pay-with-check-label {
    font-weight: 500;
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }

  .pay-with-check-info {
    border: 2px solid rgba(43, 105, 184, 1);
    display: flex;
    padding: 50px 30px;
    border-radius: 8px;
    line-height: 20px;
  }
}

.subscription-transactions-included {
  background: #EEF0FD;
  margin: 0;
  margin-top: 20px;
  padding: 25px 15px;
  border-radius: 8px;

  .transaction-label {
    margin-left: 18px;
    font-weight: 500;
    color: #556EE6;
    line-height: 24.5px;
    display: list-item;
    font-size: 14px;
  }
}
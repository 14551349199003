.user-avatar {
  display: inline-block;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  box-sizing: content-box;
  border-style: solid;
  border-color: #f5f4f8;

  &.user-avatar-sm {
    width: 33px;
    border-width: 3px;

    &.in-table {
      margin-top: -10px;
      margin-bottom: -10px;
    }

    .user-avatar-initials {
      font-size: 13px;
    }

    .user-avatar-icon {
      font-size: 16px;
    }
  }

  &.user-avatar-md {
    width: 48px;
    border-width: 4px;

    .user-avatar-initials {
      font-size: 18px;
    }

    .user-avatar-icon {
      font-size: 23px;
    }
  }

  &.user-avatar-lg {
    width: 64px;
    border-width: 5px;

    .user-avatar-initials {
      font-size: 25px;
    }

    .user-avatar-icon {
      font-size: 31px;
    }
  }

  &.user-avatar-xl {
    width: 133px;
    border-width: 10px;

    .user-avatar-initials {
      font-size: 52px;
    }

    .user-avatar-icon {
      font-size: 64px;
    }
  }

  &.user-avatar-xxl {
    width: 200px;
    border-width: 15px;

    .user-avatar-initials {
      font-size: 78px;
    }

    .user-avatar-icon {
      font-size: 96px;
    }
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.user-avatar-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-avatar-noimg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}
.payment-plan-selection-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .invalid-feedback {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
  }
}

.payment-plan-selection-slider {
  // 16px = plan right padding
  max-width: calc(100% - 16px);
}

.payment-plan-selection-item {
  flex: 0 0 400px;
  margin-right: 26px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  outline: 2px solid #808080;
  border-radius: 16px;
  overflow: hidden;
}

.payment-plan-selection-item-selected {
  flex: 0 0 400px;
  margin-right: 26px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  outline: 6px solid #556EE6;
  border-radius: 16px;
}

.payment-plan-selection-item-box {
  flex: 1 1 100%;
  height: 100%;
  overflow: hidden;
}

.payment-plan-selection-item-name {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
  margin: 0px 0px 0px 12px;
  color: #2E2E2E;
  display: flex;
  align-items: center;
}

.payment-plan-selection-item-price {
  font-size: 36px;
  line-height: 72px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0;
  height: 170px;
  color: #2E2E2E;

  span {
    font-size: 32px;
    font-weight: 500;
  }
}

.payment-plan-selection-item-description {
  padding: 0 14px;
  font-size: 16px;
  line-height: 24px;

  p {
    position: relative;
  }

  i {
    position: absolute;
    top: 0;
    right: 100%;
    margin-right: 10px;
    font-size: 24px;
    color: #000000;
  }
}

.payment-plan-selection-item-recommended {
  flex: 0 0 64px;
  background-color: $primary;
  color: $white;
  font-size: 24px;
  line-height: 64px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  margin-top: -12px;
}

.payment-plan-selection-item-active {
  border-width: 5px;
  background-color: $white;
}

.payment-plan-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #EFEFEF;
  padding: 12px;
  height: 90px;
}

.payment-plan-item-header-selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #556EE6;
  padding: 12px;
  height: 90px;
}

.payment-plan-selection-starts-from {
  font-size: 16px;
}

.plan-confirmation-box {
  display: flex;
  border: 1px solid #EFF2F7;
  margin: 12px 24px;
}

.new-plan-box {
  display: flex;
  border: 3px solid #556EE6 !important;
  margin: 12px 24px;
}

.left-confirmation-box {
  padding: 24px;
  background-color: #EFEFEF;
  min-width: 190px;
  height: 84px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-confirmation-box {
  padding: 24px;
  text-align: left;
  height: 84px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.vertical-item-price {
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0;
  color: #2E2E2E;
}

.payment-plan-card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 90px);
}

.payment-plan-selection-actions {
  height: 220px;
}
#recent-list.scrollbar-container {
  @media screen and (min-width: 768px) {
    height: 410px;
  }
}

.chat-conversation {
  .chat-item {
    clear: both;
  }
}

// .chat-leftsidebar {
//   max-width: 380px;
// }

.clear-float {
  clear: both;
}

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $primary;
  color: $primary;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: $primary;
  }

  50%,
  100% {
    background-color: rgba(85, 110, 230, 0.2);
  }
}

.conversation-content-image {
  max-width: 150px;
  cursor: pointer;
}

.chat-input-links {
  right: 5px;
}

.chat-input-links li button {
  font-size: 20px;
}

.chat-dropzone {
  position: relative;

  .dropzone {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    min-height: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: opacity .5s;

    .dz-message {
      padding: 0;
    }

    &.is-drag-active {
      z-index: 2;
      border-color: $info;
      opacity: 1;
    }
  }
}

.dz-preview {
  display: flex;
  flex-flow: row wrap;
}

.dz-preview-item {
  position: relative;
  flex: 0 0 45px;
  height: 45px;
  margin-right: 8px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid $gray-400;
  padding: 1px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  .dz-upload-progress {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 30%;
    background-color: #ffffff;
  }

  .dz-preview-loader {
    position: absolute;
    bottom: 0;
    right: 1px;
    line-height: 1;
    color: $dark;
    font-size: 14px;
  }
}

.chat-gallery {

  .pswp__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gallery-preview-img {
    max-width: 100vw !important;
    max-height: 100vh;
  }
}

.unread-messages-btn {
  padding: 5px 10px;
  background-color: $primary;
  border-radius: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 4px;
  position: absolute;
  left: 50%;
  top: -15px;
  z-index: 90;
  transform: translatex(-50%);

  &:hover {
    color: #fff;
  }
}
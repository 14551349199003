$blue: #2B69B8;
$tooltip-bg: #DAE9F9;

.table.new-order-uploaded-docs {
  tr.deleting {
    text-decoration: line-through;
    opacity: 0.5;
  }

  tr.processing {
    color: #aaaaaa;
  }
}

.order-instructions {
  counter-reset: order-instructions-counter;
  list-style-type: none;
  padding: 0 0 0 36px;
  margin: 0;
}

.order-instructions li {
  position: relative;
  margin-bottom: 24px;
  color: $gray-600;

  strong {
    font-weight: 500;
    font-size: 15px;
    color: $body-color;
  }
}

.order-instructions li::before {
  content: counter(order-instructions-counter);
  counter-increment: order-instructions-counter;
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 18px;
  border: 2px solid $success;
  color: $success;
  border-radius: 50%;
  text-align: center;
  margin-right: 16px;
  position: absolute;
  right: 100%;
  font-size: 12px;
  font-weight: 600;
}

.vid-image {
  background-color: #000000;
  padding-bottom: 56.25%;
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
  }

  &.blured {
    filter: blur(8px);
  }
}

.vid-image-preview {
  position: relative;
  height: 100%;
  text-align: center;
  border: 20px solid transparent;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: contain;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }

  &.blured {
    filter: blur(8px);
  }
}

.vid-image-title {
  font-size: 14px;
  font-weight: 500;
  display: block;
}

.vid-signature-image {
  height: 50px;
  margin-right: 50px;
}

.vid-response-card {
  font-family: Lato;
}

.vid-response-card>.card-body>p {
  font-size: 16px;

  strong {
    font-weight: 700;
  }
}

.vid-response-line-items {
  max-width: 335px;

  td,
  th {
    padding-left: 0;
    padding-right: 0;
  }

  th {
    font-size: 12px;
    font-weight: 700;
    color: #999999;
  }

  .score {
    font-weight: 600;
  }
}

.vid-accordion-container {
  .accordion {
    // fixes horizontal scrollbar flickering during transition
    overflow-x: hidden;
  }

  .accordion-button {
    padding-left: 0;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;

    &:not(.collapsed) {
      box-shadow: none;
    }

    span {
      flex: 1 1 auto;
    }

    strong {
      font-weight: 600;
      margin-right: 22px;
    }
  }

  .accordion-body {
    padding: 0 5px 0 0;
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
  }

  .vid-accordion-head {
    font-size: 12px;
    font-weight: 700;
    color: #999999;
    display: flex;
    justify-content: space-between;
    padding: 1rem 50px 1rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .vid-accordion-assist {
    font-size: 14px;
    font-weight: 700;
    color: #999999;
    display: flex;
    justify-content: space-between;
    padding: 10px 50px 10px 0;
    border-bottom: 1px solid #eeeeee;
  }

  .vid-accordion-list {
    // max-height: 365px;
    // overflow-y: auto;

    .accordion-button::after {
      width: 32px;
      height: 32px;
      background-position: center center;
      margin-left: -8px;
      margin-right: -8px;
      margin-top: -8px;
      margin-bottom: -8px;
    }

    .accordion-button:not(.collapsed)::after {
      background-color: #eeeeee;
      border-radius: 50%;
    }
  }

  .vid-alert-notifications {
    padding: 0;
    list-style-type: none;
  }
}

.seal-shield-icon {
  height: 24px;
  margin-top: -3px;
  margin-bottom: -3px;
}

.seal-spinner-icon {
  font-size: 38px;
  color: #2b69b8;
  line-height: 1;
}

.seal-toast-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  text-align: right;
  padding: 90px 24px 0 0;
  z-index: 1005;
}

#sealing_toast {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: $box-shadow-lg;
  border-radius: 0.25rem;
}

.seal-err-icon {
  font-size: 33px;
  line-height: 1;
}

.link-qr-icon {
  position: relative;

  .link-qr-popup {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
    z-index: 100;
    display: none;
  }

  &:hover .link-qr-popup {
    display: block;
  }
}

.mav-seal-badge {
  height: 90px;
}

.last-message-wrap {
  overflow: hidden;

  .conversation-name {
    font-weight: $font-weight-semibold;
    color: $primary;
    margin-bottom: 4px;
  }

  .message-content {
    max-height: 94px;
    overflow: auto;
  }

  .message-image-content img {
    max-height: 94px;
    width: auto;
    max-width: 100%;
  }

  .chat-time {
    font-size: 12px;
    text-align: end;
  }
}

.nav-tab {
  border-radius: 0.25rem 0.25rem 0rem 0rem !important;
}

.active-content {
  background-color: $primary;
  color: white !important;
}

.order-vehicles {
  .additional-section {
    padding: 10px;

    &:nth-child(even) {
      background-color: #EEF4FD;
    }
  }
}

.order-signers {
  .additional-section {
    padding: 10px;
    background-color: #EEF4FD;
  }
}

.nav-number {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left: 2px;
  line-height: 32px;
  border: 1px solid #ced4da;
  border-radius: 50%;
}

.nav-tab:hover {
  .nav-number {
    z-index: 2;
    color: $primary;
    text-decoration: none;
    background-color: #eff2f7 !important;
    border-color: $primary !important;
  }
}

.nav-tab.active {
  .nav-number {
    color: $primary;
    background-color: white;
  }
}

.instruction-subtitle {
  margin-left: -36px;
}

.delivery-instructions {
  .delivery-instructions-header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 32px;
  }

  .delivery-instructions-title {
    color: $primary;
    font-weight: 500;
    margin-left: 15px;
  }

  p {
    margin-bottom: 0;
  }
}

.blurred {
  opacity: 0.5;
  pointer-events: none;
}

/* Special instruction */
.special-instructions-label {
  cursor: pointer;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: $blue;
  text-align: left;
  position: relative;
  max-width: 220px;

  .spacial-instructions-tooltip {
    background: $tooltip-bg;
    padding: 8px 20px;
    text-align: left;
    border-radius: 4px;
    min-width: 250px;
    height: auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in;
    position: absolute;
    z-index: 2;
    left: auto;
    right: 40px;
    top: 50px;
    width: 351px;

    .tooltip-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: $gray-800;
    }

    p {
      font-family: Poppins;
      font-size: 13px;
      line-height: 20px;
      color: $gray-600;
      padding-left: 10px;
    }

    .arrow-up {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $tooltip-bg;
      position: absolute;
      top: -10px;
      left: 53%;
    }
  }

  &:hover {
    .spacial-instructions-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  .row+& {
    .spacial-instructions-tooltip {
      left: 0;
      right: auto;
    }

    .arrow-up {
      left: 2px;
    }
  }
}

/* Tracking and Shipping Section */
.package-number {
  padding: 2px 6px 2px 6px;
  border-radius: 27px;
  background: rgba(43, 105, 184, 0.2);
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $blue;
}

/* Styles for modal with youtube video from dashboard */

.modal-header-dashboard {
  border-bottom: 0px !important;
}

.modal-body-dashboard {
  padding: 20px 50px 40px 50px;

  @media screen and (max-width: 767px) {
    padding: 20px 20px 40px 20px;
  }
}

.player {
  border-radius: 20px !important;
  margin-top: 20px
}

.chat-breadcrumbs {
  float: right;
  margin-top: -45px;
  font-size: 14px;

  @media screen and (max-width: 1359px) {
    margin-top: 0px;
    margin-bottom: 20px;
    float: left
  }
}

/** IN-STORE ORDER INFORMATION **/

.qr-code-wrapper {
  background: #F8F9FA;
  display: flex;
  flex-direction: row;
  padding: 20px;
  flex-wrap: wrap;

  .qr-image {
    position: relative;
    margin-bottom: 30px;
    margin-right: 60px;

    .phone-image {
      height: 100%;
      margin-left: 30px;
    }

    .mav-logo-simple {
      position: absolute;
      left: 50%;
      transform: translate(-9%, 130%);
      width: 40px;
    }

    .qr-parent {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-37%, 92%);
    }
  }

  .helper-text {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .qr-helper-line {
      line-height: 22.5px;
      font-weight: 500;
      font-size: 15px;
      color: #495057;
    }
  }
}

.terms-of-service-btn {
  border: 0;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
  font-style: italic;

  &:hover,
  &:active,
  &:focus {
    border: 0;
    text-decoration: underline;
    outline: none;
    box-shadow: none;
  }
}

.terms-of-service-label {
  color: #50575e !important;
}

.outgoing-label-card {
  background-color: rgba(233, 242, 255, 1);
  color: rgba(116, 120, 141, 1)
}

.info-icon-blue {
  color: $new-blue;
}

/************* This input font size is used to prevent zoom in when input is focused on mobile devices*************/
input,
textarea {
  @media(max-width: 768px) {
    font-size: 16px !important;
  }
}
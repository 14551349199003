.order-docs-manager {
  .doc-list-header {
    display: flex;
    border-top: 1px solid #eff2f7;

    .doc-list-col {
      font-size: 13px;
      font-weight: 600;
    }

    &::after {
      flex: 0 0 auto;
      width: 16px;
      height: 16px;
      content: "";
      margin-right: 16px;
    }
  }

  .doc-list-col {
    padding: 12px 16px;
  }

  .doc-no {
    flex: 0 0 4%;
  }

  .doc-name {
    flex: 1 1 100%;
  }

  .doc-status {
    flex: 0 0 16%;
  }

  .doc-up-by {
    flex: 0 0 13%;
  }

  .doc-up-date {
    flex: 0 0 10%;
  }

  .doc-pages {
    flex: 0 0 10%;
  }

  .doc-sign-date {
    flex: 0 0 10%;
  }

  .doc-actions {
    flex: 0 0 8%;
  }

  .ink-sign-accordion {
    .accordion-item {
      border: 0;

      &.deleting {
        text-decoration: line-through;
        opacity: 0.5;
      }
    }

    .accordion-button {
      box-shadow: none !important;
      display: flex;
      padding: 0;

      &::after {
        flex: 0 0 auto;
        margin-right: 16px;
      }

      .doc-name {
        display: flex;
        align-items: center;
      }

      .doc-actions .dropdown-toggle {
        font-size: 16px;
        line-height: 29px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .accordion-body {
      padding: 0;
    }

    .ink-sign-pages {
      display: flex;
      flex-flow: row wrap;
    }

    .ink-sign-page {
      width: 160px;
      margin: 0 10px 16px;
    }

    .ink-sign-page-title {
      font-size: 15px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      margin-bottom: 12px;
    }

    .ink-sign-page-thumb {
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: #ffffff;
      position: relative;
    }

    .ink-sign-page-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }

    .ink-sign-page-btn {
      position: absolute;
      top: 5px;
      border-radius: 50%;
      font-size: 28px;
      padding: 0;
      width: 50px;
      height: 50px;
      border-color: transparent;
    }

    .ink-sign-page-del-btn {
      right: 5px;
      background-color: rgba(270, 90, 90, 0.5);

      &:hover {
        background-color: rgba(270, 90, 90, 1);
      }
    }

    .ink-sign-page-add-btn {
      left: 5px;
      background-color: rgba(116, 120, 141, 0.5);

      &:hover {
        background-color: rgba(116, 120, 141, 1);
      }
    }

    .ink-sign-page-thumb:not(:hover) .ink-sign-page-del-btn,
    .ink-sign-page-thumb:not(:hover) .ink-sign-page-add-btn {
      display: none;
    }

    .ink-sign-add-page {
      cursor: pointer;

      .ink-sign-page-thumb {
        font-size: 28px;
      }
    }
  }

  .order-docs-table {
    td {
      vertical-align: middle;
    }

    tr.deleting {
      text-decoration: line-through;
      opacity: 0.5;
    }

    tr.processing {
      color: #aaaaaa;
    }

    .doc-actions .dropdown {
      margin-top: -5px;
      margin-bottom: -5px;
    }
  }
}

.ink-sign-gallery {
  &.pswp {
    z-index: 1040;
  }

  .pswp__content {
    text-align: center;
  }

  .ink-sign-gallery-item {
    margin: 50px auto 0;
    display: inline-block;
    background-color: white;

    .ink-sign-gallery-item-img {
      border: 5px solid #eff2f7;
      // viewport - spacing
      max-height: calc(100vh - 100px - 100px);
    }

    .ink-sign-gallery-item-btns {
      padding: 32px;
      text-align: right;
    }
  }
}

.reject-doc-page-modal {
  .alert-danger {
    background-color: $danger;
    color: $white;
    border: none;
    margin-bottom: 0;
  }
}

.notarized-badge {
  background: $cyan;
  padding: 4px 8px;
  border-radius: 30px;
  font-size: 8px;
  line-height: 12px;
  color: $white;
}

.captured-as-pdf-icon {
  font-size: 21px;
}

.order-docs-info {
  background-color: #D4DAF9;
  border-radius: 5px;
  padding: 8px 12px;
  width: auto;
  border: 1px solid #C1C7E2;
}